.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #00b46e;
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.root {
  padding: 50px 0;
}

.full_width {
  flex: 0 0 75%;
  max-width: 75%;
}
.mobile_view {
  padding-bottom: 140px;
}
.mobile_view .full_width {
  max-width: 100%;
  flex: 1;
}
.mobile_view [class*='tabs'] [class*='MuiTabs-fixed'] {
  overflow-x: auto;
}
.mobile_view [class*='tabs'] [class*='MuiTabs-centered'] {
  justify-content: unset;
}
@media (max-width: 767px) {
  .title {
    font-size: 27px;
    margin-bottom: 20px;
  }
  .root_mobile {
    padding: 0 0 50px !important;
  }
  .full_width_mb {
    width: 100% !important;
    margin: 0 !important;
  }
}
.wrapDirect {
  padding-top: 36px;
}
.wrapDirect a {
  color: #868585;
  font-family: googlesansmedium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-transform: capitalize;
}
.wrapDirect nav ol li:last-child a {
  cursor: default;
  color: #202332;
}
@media (max-width: 950px) {
  .full_width {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
}
