.titleTab {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #292929;
  font-family: googlesansregular;
}
.titleTab:hover {
  color: #00b46e;
}
.tab_active {
  color: #00b46e !important;
  outline: none !important;
  cursor: pointer;
}
.tab_inactive {
  outline: none !important;
}

/* for icon */
.tab_active p {
  color: #101754 !important;
  font-weight: 500;
  font-size: 14px;
  font-family: googlesansmedium;
  line-height: 18px;
}
.wrapMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 250px;
  padding: 8px;
  border-top: 1px solid #e3e3e3;
  box-sizing: border-box;
}
.wrapMenu:hover {
  cursor: default !important;
}
.wrapMenu a:hover {
  cursor: pointer !important;
}
.wrapMenu:last-of-type {
  border-bottom: 1px solid #e3e3e3 !important;
}
.menuIcon {
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
}
.cn {
  font-weight: 500;
  font-size: 16px;
  font-family: googlesansmedium;
  line-height: 18px;
  padding: 0 24px;
  margin-top: 12px;
}
.menuLabel {
  /* min-width: 190px; */
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab_inactive:hover {
  color: #00b46e !important;
  cursor: pointer;
}

/* for icon */
.tab_inactive:hover svg {
  color: #00b46e !important;
}

.WrapSilder {
  /* flex: 0 0 25%; */
  max-width: 25%;
}
.WrapSilder a {
  max-width: 260px;
  margin-right: 14px !important;
}

.wrapBox a:hover {
  cursor: default;
}
.account_name {
  padding-bottom: 30px;
}

.name {
  font-weight: bold !important;
  text-transform: capitalize;
}

@media only screen and (max-width: 950px) {
  .WrapSilder {
    display: none !important;
  }
}
