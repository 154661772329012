.item_breadcrumb {
  font-family: googlesansmedium !important;
  color: #8d8d8d;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.75;
  text-transform: capitalize;
}

.last_breadcrumb {
  color: #000000 !important;
}

@media (max-width: 476px) {
  .item_breadcrumb {
    font-size: 14px;
  }
}