.root_card {
  display: flex;
  flex-direction: row;
  position: relative;
  height: inherit;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.important_item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  position: absolute !important;
  left: -16px !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  background-color: white !important;
  width: 32px;
  height: 32px;
  padding: 0 !important;
  color: #919aa3;
  border-radius: 50%;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
}

.ordering {
  width: 20px;
  height: 20px;
  border-radius: 10%;
  background-color: #919aa3;
  color: #fff;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  left: -8px !important;
  top: 0% !important;
}

.important_item:hover {
  color: #f9b514;
}

.root_card:hover {
  z-index: 1;
  background-color: #f9f9f9;
}

.root_card .product_card:hover {
  background-color: #f9f9f9;
}

.button_container {
  justify-self: center;
  width: 100%;
}

.product_card {
  width: 100%;
  height: 100%;
  padding: 12px 16px 12px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: unset !important;
  transition: all 0.25s;
}

.product_card.error {
  background-color: #fdecec;
}

.product_image {
  max-width: 90px;
  margin: 10px !important;
}

.star_icon {
  width: 0.8em !important;
  height: 0.8em !important;
}

.cart_content_web {
  display: contents;
}
.hidden_gift {
  display: none;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .product_image {
    max-width: 50px;
  }
}

@media (max-width: 767px) {
  .product_content {
    padding-right: 20px !important;
    margin-bottom: 10px;
  }

  .cart_content_mobile {
    width: 100%;
  }

  .cart_content_mobile [class*='price_wrapper'] {
    margin: 0;
    flex: 0;
    max-width: 100%;
    align-items: flex-start;
  }

  .cart_content_mobile [class*='product_action'] {
    max-width: 125px;
    margin-left: auto;
  }

  .important_item {
    width: 20px;
    height: 20px;
    left: -10px !important;
  }

  .important_item svg {
    font-size: 1rem !important;
  }
}

@media (min-width: 768px) and (max-width: 959px) {
  .product_content {
    min-width: 330px;
    max-width: calc(100% - 300px);
  }

  .important_item {
    left: -6px !important;
  }
}

@media (min-width: 960px) {
  .product_card {
    flex: 15%;
    justify-self: start;
  }

  .product_content {
    flex: 33%;
    max-width: 33%;
  }
}
