.container {
  height: 100%;
  padding: 2px 0;
  display: flex;
  justify-content: center;
}

.container [class*='MuiPaper-elevation'] {
  box-shadow: none !important;
}

.container [class*='MuiCard-root'] {
  overflow: inherit;
}

.product_card {
  max-width: 230px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-top: 5px; */
  align-content: stretch;
}

.product_card_bestProduct {
  width: 100%;
  max-width: 260px;
}

.product_card:hover {
  border: 1px solid #0bd577 !important;
}

.product_tag {
  position: absolute;
  left: -1px;
  top: -1px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #d4323b;
  border-radius: 12px 0px;
  padding: 4px 0px;
  font-family: googlesansmedium;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.1px;
  color: #ffffff;
  font-size: 12px;
}

.product_tab_contract {
  background-color: #3b438f !important;
}

.icon_wrapper {
  display: block;
  position: relative;
  z-index: 1;
}

.favorite_icon {
  color: #0cba69;
}

.favorite_icon_wrapper {
  position: absolute;
  top: -10px;
  right: -4px;
  color: #0cba69;
  font-size: 25px;
  cursor: pointer;
  z-index: 1;
}

.favorite_icon_wrapper:hover {
  color: #0cba69;
}

.content_container {
  margin-top: 5px;
}

.product_image [class*='MuiCardContent-root'] {
  /* padding: 0 0; */
  /* margin-top: 8px; */
  overflow: hidden;
  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */
  padding: '20px';
}

.product_image a {
  justify-content: center;
  align-items: center;
}

.image {
  -o-object-fit: contain;
  object-fit: contain;
  position: 'relative';
}

.image_mobile {
  margin-top: 10px;
}


/* style for mobile v2  */

.product_card_mv2 {
  border-radius: 8px;
  border: 1px solid #E9E9E9;
  font-family: ggsm;
  font-size: 12px;
}


.product_tag_mv2 {
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 12x;
  line-height: 13px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;
  text-transform: capitalize;
  padding-left: 0px;
  padding-right: 0px;
  height: 20px;
}


@media (max-width: 1200px) {
  .product_card_bestProduct {
    width: calc(100% - 15px);
    max-width: 260px;
  }
}

@media (max-width: 576px) {
  .product_card_bestProduct {
    width: calc(100% - 20px);
    max-width: 240px;
  }
}

@media (max-width: 412px) {
  .product_card_bestProduct {
    width: calc(100% - 10px);
    max-width: 220px;
  }
}

@media (min-width: 600px) {
  .favorite_icon_withnew {
    font-size: 28px;
  }

  .favorite_icon_withoutnew {
    font-size: 26px;
  }
}

@media (min-width: 1024px) {
  .favorite_icon_wrapper {
    font-size: 28px;
  }

  .favorite_icon_withoutnew {
    font-size: 26px;
  }
}

@media (min-width: 1280px) {
  .favorite_icon_wrapper {
    font-size: 28px;
  }

  .favorite_icon_withoutnew {
    font-size: 26px;
  }
}