.instruction_text {
  display: flex;
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  color: #919aa3 !important;
  padding: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.info_text {
  display: flex;
  align-items: flex-start;
  background-color: rgb(231, 243, 255);
  border-radius: 10px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  color: rgb(9, 57, 102) !important;
  padding: 1rem !important;
  margin-bottom: 1.5rem !important;
}

.alert {
  width: 100%;
}

.deleteSeletedBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 20px;
}

.hidden {
  visibility: hidden;
}

.star_icon {
  margin-right: 5px;
  color: #ffc107 !important;
}

.vat_icon {
  margin-right: 5px;
  color: #ff0707 !important;
}

.info_icon {
  margin-right: 5px;
  color: #919aa3 !important;
  width: 20px !important;
  height: 20px !important;
}

.deleteBtn:hover {
  cursor: pointer;
  color: rgb(247, 30, 30);
}

.deleteBtn {
  color: rgb(207, 6, 6);
}

.deleteBtn [class*='MuiSvgIcon-root'] {
  vertical-align: text-bottom !important;
}

.quick_order {
  color: #00b46e !important;
  text-decoration: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.quick_order:hover {
  color: #00683f !important;
}

.text_area {
  display: block;
  width: 100%;
  height: 111px !important;
  padding: 0.475rem 0.75rem;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: vertical;
}

.text_area:focus {
  color: #495057;
  background-color: #fff;
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 180, 110, 0.25);
}

.note_title {
  font-weight: 500 !important;
  margin-bottom: 8px;
  font-size: 16px;
}

.note_content {
  color: #919aa3 !important;
  margin-bottom: 8px;
  font-size: 16px;
}

.btn {
  align-self: end;
  color: #212529 !important;
  background-color: #f9b514 !important;
  border-color: #f9b514 !important;
  border-radius: 50px !important;
  text-transform: capitalize !important;
  padding: 6px 15px !important;
}

.notes {
  padding: 1rem !important;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
}

.sellerAvatar {
  margin-right: 10px !important;
}

.headerGroup {
  display: flex;
  justify-content: space-between;
  background: white;
  padding: 20px;
}

.headerGroupMobile {
  background: white;
  padding: 20px;
}

.checkboxGroup {
  float: right;
}

.checkboxGroupMobile {
  display: flex;
  justify-content: flex-end;
}

.linkComp {
  padding: 0 !important;
}

.checkbox {
  margin-left: 2px !important;
}

.wrapActionCart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.btnChoosen {
  color: #212529;
  min-width: 140px;
  text-transform: capitalize;
}

.btnChoosen > p {
  font-weight: bold;
}

.btnChoosen > span > span {
  margin-right: 10px !important;
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.btnDelete {
  border-radius: 20px;
  background-color: #ffffff;
  color: #212529;
  border: 0.5px solid black;
  max-height: 36px;
  /* // Thuannc - 25May2022 -> tắt bold cho dòng text này - anh Nam kêu */
  /* font-weight: bold; */
  padding: 6px 16px;
}

.btnDelete p {
  /* font-family: 'ggsr' */
  /* // Thuannc - 25May2022 -> tắt bold cho dòng text này - anh Nam kêu */
  /* font-weight: bold; */
}

.btnDelete > span > span {
  margin-right: 10px !important;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.wrapBtnAction {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.btnYes {
  background-color: #00b46e !important;
  color: white;
  min-width: 120px;
}

.btnNo {
  background-color: #adadad;
  min-width: 120px;
}

.btnNo:hover {
  background-color: #9a9a9a;
}

.wrapDiablog {
  padding: 40px !important;
  border-radius: 20px !important;
}

.disabled {
  background-color: #c9c9c9;
  border: transparent;
}

.disabled:hover {
  background-color: #c9c9c9;
  color: #ffffff;
}

@media (max-width: 855px) {
  .btnDelete p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 480px) {
  .wrapActionCart {
    flex-direction: column;
    align-items: flex-start;
  }

  .btnDelete {
    padding: 6px 6px;
  }

  .deleteSeletedBtn {
    justify-content: center;
  }
}

.remove_item {
  line-height: 0;
  display: block;
  padding: 10px;
  margin-top: 20px;
}

.remove_item_content {
  line-height: 1;
  display: block;
  width: calc(100% - 80px);
  float: left;
  padding: 10px;
}

.remove_item_image {
  width: 80px;
  float: left;
}

.remove_item_cart_name {
  color: #343a40;
  text-transform: capitalize;
  transition: color 0.2s;
  font-weight: 500;
  margin-bottom: 10px;
  margin-bottom: 10px;
}

.remove_item_cart_price {
  color: #00b46e;
  font-weight: 500;
}
.flex_sb {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
}
