.root_card {
  display: flex;
  flex-direction: row;
  position: relative;
  height: inherit;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.root_card:hover {
  z-index: 1;
  background-color: #f9f9f9;
}

.root_card .product_card:hover {
  background-color: #f9f9f9;
}

.button_container {
  justify-self: center;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: 0;
  font-family: ggsm;
}

.product_card {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: unset !important;
}

.product_card.isdeal {
  /* box-shadow: inset 3px 0px 0px 0px #cd0000 !important; */
  box-shadow: none;
}

.product_image {
  flex: 15%;
  justify-self: start;
  max-width: 60px;
  margin-right: 10px !important;
  margin-bottom: 0 !important;
}

.image {
  object-fit: contain;
}

/* style cho mobile v2 */

.button_container_mv2 {
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  flex: none;
  order: 0;
  background-color: #fff;
  flex-grow: 0;
  margin-bottom: 10px;
  font-family: ggsm;
  position: relative;
}

.product_card_mv2 {
  padding: 8px;
}

.root_card_mv2 {
  border-bottom: none;
}

.mobileRight_box {
  width: 100%;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .rightBox {
    width: 100%;
    min-height: 140px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .product_card {
    min-height: 125px;
    justify-content: flex-start;
    /* align-items: flex-start;
     */
  }

  .rightBox [class*='price_wrapper_column'] {
    max-width: 100%;
    display: block;
  }

  .rightBox [class*='danger_column'] {
    position: unset;
    text-align: left;
  }

  .rightBox [class*='product_action_column'] {
    position: absolute;
    right: 0;
    top: 15px;
    display: flex;
    flex-direction: column-reverse;
  }

  .rightBox [class*='root_input'] {
    border: none !important;
    width: 60px;
    margin: 0 !important;
    margin: 5px 0 !important;
    color: #c3ccdc !important;
  }

  .rightBox [class*='product_type'] {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    padding: 48px 0;
  }

  .product_content_column {
    min-width: 442px;
  }

  .product_image {
    margin-bottom: 0.5rem !important;
  }
}