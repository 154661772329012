.sidebar__user {
  background-color: white;
  color: #919aa3;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  display: flex;
  font-size: smaller;
  position: relative;
  margin: 4em 1em 1em;
}

.sidebar__user_wallet {
  width: 50%;
  padding: 1em;
  border-right: 1px solid #c3ccdc;
}

.sidebar__user-wallet_amount {
  color: #00b46e;
  font-size: large;
  font-weight: 500;
}

.unit {
  padding-left: 0.25em;
}

.sidebar__user_avatar {
  position: absolute;
  top: calc(-1 * 80px / 2);
  left: calc(50% - 80px / 2);
  background: linear-gradient(102.04deg, #46b759 0%, #5bba4c 100%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__user_avatar img {
  border-radius: 50%;
  border: 3px solid white;
  max-width: 64px;
  height: auto;
  vertical-align: middle;
}

.sidebar__user_bonus_point {
  width: 50%;
  text-align: right;
  padding: 1em;
}

.sidebar__user_wallet_amount {
  color: #00b46e;
  font-size: large;
  font-weight: 500;
}

.sidebar__user_bonus_point_amount {
  color: #f9b514;
  font-size: large;
  font-weight: 500;
}

.sidebar__user_name {
  text-transform: capitalize !important;
  text-align: center;
  color: #fff;
}

.hr {
  background-color: white;
  opacity: 0.6;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(195, 204, 220, 0.4);
}

.items {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  flex-direction: column !important;
}

.sidebar__item_link {
  color: white;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center;
  padding: 0.5rem 1rem !important;
  position: relative;
  padding-top: 15px;
}

.sidebar__item_linkHome {
  color: white;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center;
  padding: 0.5rem 1rem !important;
  position: relative;
  padding-top: 15px;
}

.sidebar__item_linkHome p {
  margin-left: 5px !important;
  font-weight: 500 !important;
  position: relative;
}

.sidebar__item_linkHome [class*='svg-inline--fa'] {
  font-size: 20px !important;
}

.sidebar__item_linkHome .badge {
  position: absolute;
  left: 155px;
  right: unset;
  top: -1px;
}

.sidebar__item_linkHome p {
  margin-left: 25px !important;
}

.sidebar__item_linkHome svg {
  max-width: 20px !important;
  min-width: 20px;
}

.sidebar__item_link p {
  margin-left: 5px !important;
  font-weight: 500 !important;
  position: relative;
}

.sidebar__item_link [class*='MuiIcon-root'] {
  width: 2em;
}

.sidebar__item_link [class*='MuiSvgIcon-root']+p {
  margin-left: 25px !important;
}

.sidebar__item_link span {
  min-width: 20px !important;
  max-width: 20px !important;
  margin-right: 20px !important;
}

/* .sidebar__item_link [class*='svg-inline--fa'] + p {
  margin-left: 25px !important;
} */
.sidebar__item_link.active {
  background-color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}

.activeHome {
  background-color: rgba(255, 255, 255, .3);
  text-decoration: none;
}

.sidebar__item_link [class*='material-icons'],
.sidebar__item_link [class*='MuiSvgIcon-root'],
.sidebar__item_link [class*='svg-inline--fa'] {
  font-size: 20px !important;
}

.sidebar__item_link .badge {
  position: absolute;
  left: 155px;
  right: unset;
  top: -1px;
}

.sidebar__header {
  padding-left: 1rem;
  font-size: 1rem;
  color: #fff;
  margin: 0;
}

.badge {
  position: relative;
  right: -1.25em;
  top: 1px;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  display: inline-block;
  padding: 0.25em 0.5rem;
  font-size: 75%;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: linear-gradient(to right, #ff4fae, #f9b514);
  color: white;
  border: 1px solid white;
  font-size: 12px;
}

.contact_more {
  padding: 0.5rem 1rem !important;
}

.open_hours {
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
}

.fb {
  color: #3b5998;
  margin: 10px 0 5px;
}

.mt2 {
  margin: 5px 0 10px;
}

/* .icon {
  width: 1em !important;
  margin-right: 1em !important;
} */
.TitleConnect {
  display: flex;
  align-items: center;
  margin-left: 18px;
}

.TitleConnect div {
  background-color: #ffffff;
  margin-right: 24px;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.beta {
  font-size: 0.875rem;
  font-family: ggsr, sans-serif;
  line-height: 1.43;
  margin-left: 5px !important;
  font-weight: 500 !important;
  position: relative;
  color: #fff;
  padding: 0px;
}